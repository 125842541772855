/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { WorldsWebModels } from '@/store/worldsWebModels/types'
import { ModelChat } from '@/components/pages/worlds-web-models/viewing-chat/worlds-web-models-viewing-chat.interface'
import { getLocalStorageService } from '@/services/storage.service'
const namespace: string = 'worldsWebModels';

export default class WorldsWebModelsViewingChatPage extends Vue {
	@State('worldsWebModels') worldsWebModels: WorldsWebModels | undefined;
	@Action('storeChatMessage', {namespace}) storeChatMessage: any;
	@Action('storeModelTodo', {namespace}) storeModelTodo: any;
	@Action('getCardModelInfo', {namespace}) getCardModelInfo: any;
	@Action('getCardModelServices', {namespace}) getCardModelServices: any;
	@Action('storeModelViewingPerMinOrAll', {namespace}) storeModelViewingPerMinOrAll: any;
	@Action('storeModelViewingPeep', {namespace}) storeModelViewingPeep: any;
	@Getter('getMessageFromChat', {namespace}) getMessageFromChat: any;
	@Getter('getModelInfo', {namespace}) getModelInfo: any;

	modelChat: ModelChat = {
		chatMessage: {
			userName: '',
			message: ''
		}
	}

	modelTodoButton: string = '';

	sendChatMessage() {
		this.storeChatMessage({
			username: getLocalStorageService('login'),
			message: this.modelChat.chatMessage.message
		})
		this.modelChat.chatMessage.message = '';

	}
	onModelTodoButton(event: Event & {
		target: HTMLElement
	}) {
		const {target} = event;
		this.storeModelTodo({
			todoModel: target.innerText
		})
	}
	onMainVideoFrame(type: string) {
		switch(type) {
		case 'peep':
			this.storeModelViewingPeep({
				peep: true
			});
			break;
		case 'noLogin':
			this.$router.push({ name: 'login' }).then(r => r);
			break;
		case 'min':
			this.storeModelViewingPerMinOrAll({
				perMinutes: true
			})
			break;
		case 'allShow':
			this.storeModelViewingPerMinOrAll({
				allShow: true
			})
			break;
		default:
			break;
		}
	}
	onGalery(type: string) {
		if (type === 'photo') this.$router.push({ name: 'worlds-web-models-photos' }).then(r => r);
		if (type === 'video') this.$router.push({ name: 'worlds-web-models-videos' }).then(r => r);
	}
	created() {
		this.getCardModelServices();
		this.getCardModelInfo();
	}
}
